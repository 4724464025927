import React from "react";

export const InfoIcon = () => (
  <svg viewBox="0 0 32 32">
    <path
      d="M13 0c7.18 0 13 5.82 13 13s-5.82 13-13 13S0 20.18 0 13 5.82 0 13 0zm0 2.167C7.017 2.167 2.167 7.017 2.167 13c0 5.983 4.85 10.833 10.833 10.833 5.983 0 10.833-4.85 10.833-10.833 0-5.983-4.85-10.833-10.833-10.833zM13 6.5a1.083 1.083 0 1 1 0 2.167A1.083 1.083 0 0 1 13 6.5zm1.083 11.917a1.083 1.083 0 0 1-2.166 0V11.84a1.083 1.083 0 0 1 2.166 0v6.577z"
      transform="translate(3 3)"
    />
  </svg>
);
