import React from "react";

export const SignInfoIcon = () => (
  <svg viewBox="0 0 32 32">
    <path
      d="M13 0c7.18 0 13 5.82 13 13s-5.82 13-13 13S0 20.18 0 13 5.82 0 13 0zm0 2.167C7.017 2.167 2.167 7.017 2.167 13c0 5.983 4.85 10.833 10.833 10.833 5.983 0 10.833-4.85 10.833-10.833 0-5.983-4.85-10.833-10.833-10.833zM13 6.5a1.083 1.083 0 1 1 0 2.167A1.083 1.083 0 0 1 13 6.5zm1.083 11.917a1.083 1.083 0 0 1-2.166 0V11.84a1.083 1.083 0 0 1 2.166 0v6.577z"
      fill="#FFF"
      transform="translate(3 3)"
    />
  </svg>
);

export const SignMessageIcon = () => (
  <svg viewBox="0 0 32 32">
    <path
      d="M25.563 6.438a3.191 3.191 0 0 1 3.187 3.187v12.75a3.191 3.191 0 0 1-3.188 3.188H6.438a3.191 3.191 0 0 1-3.187-3.188V9.625a3.191 3.191 0 0 1 3.188-3.188h19.125zm0 17c.585 0 1.062-.478 1.062-1.063V9.841l-9.958 8.048a1.058 1.058 0 0 1-1.334 0L5.375 9.841v12.534c0 .585.477 1.063 1.063 1.063h19.125zm-.737-14.875H7.174L16 15.695l8.826-7.133z"
      fill="#FFF"
    />
  </svg>
);

export const SignCommunityIcon = () => (
  <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.112 20.573l-5.057-8.042V8.148l9.054 15.09-3.997-2.665zM11.007 22.35h9.938l3.226 2.15H7.244l3.763-2.15zm3.923-9.819l-5.033 8.005-3.652 2.087L14.93 8.148v4.383zm1.063 2.304l3.39 5.39h-6.78l3.39-5.39zm13.661 10.18l-12.75-21.25c-.034-.055-.078-.1-.12-.146a1.047 1.047 0 0 0-.791-.369c-.32 0-.595.147-.79.369-.043.047-.088.09-.12.147l-12.75 21.25a1.09 1.09 0 0 0-.114.264c-.002.006-.002.013-.003.02a1.052 1.052 0 0 0-.014.434 1.03 1.03 0 0 0 .118.356c.018.03.043.053.063.082.035.047.067.1.108.14.04.041.088.071.133.105.031.021.057.047.087.065.05.028.103.045.157.066.034.012.064.03.1.04.08.021.161.03.243.033.011 0 .021.004.032.004h25.5a1.062 1.062 0 1 0 .912-1.61z"
      fill="#FFF"
      fillRule="evenodd"
    />
  </svg>
);

export const SignRedditIcon = () => (
  <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.333 5c-1.982 0-3.5 1.637-3.5 3.474v2.352c-2.47.167-4.694.873-6.49 1.954-.65-.507-1.421-.796-2.187-.796-.847 0-1.672.32-2.26.977-1.117 1.384-1.016 3.645.547 4.812a5.71 5.71 0 0 0-.11 1.122c0 2.379 1.45 4.45 3.573 5.862C10.03 26.167 12.874 27 16 27c3.126 0 5.97-.832 8.094-2.243 2.123-1.412 3.573-3.483 3.573-5.862 0-.385-.073-.756-.146-1.122 1.59-1.257 1.754-3.505.583-4.812-.588-.656-1.413-.977-2.26-.977-.766 0-1.536.289-2.188.796-1.795-1.081-4.02-1.787-6.49-1.954V8.474c0-.71.352-1.158 1.167-1.158.374 0 .903.199 1.787.506a11.06 11.06 0 0 0 3.172.615 2.333 2.333 0 0 0 2.041 1.195 2.33 2.33 0 0 0 2.334-2.316A2.33 2.33 0 0 0 25.333 5c-.847 0-1.595.443-2.005 1.122a8.247 8.247 0 0 1-2.443-.47C20.106 5.38 19.36 5 18.333 5zm7 1.158c.702 0 1.167.461 1.167 1.158 0 .696-.465 1.158-1.167 1.158-.701 0-1.166-.462-1.166-1.158 0-.697.465-1.158 1.166-1.158zM16 13.105c2.707 0 5.113.738 6.781 1.846 1.668 1.108 2.552 2.5 2.552 3.944 0 1.443-.884 2.836-2.552 3.944-1.668 1.108-4.074 1.845-6.781 1.845s-5.113-.737-6.781-1.845c-1.668-1.108-2.552-2.501-2.552-3.944s.884-2.836 2.552-3.944c1.668-1.108 4.074-1.846 6.781-1.846zm-9.807.036c.387 0 .797.082 1.166.29-1.125.86-2 1.918-2.515 3.112-.68-.796-.666-2.058-.073-2.823.346-.389.87-.579 1.422-.579zm19.614 0c.552 0 1.076.19 1.422.58.574.642.62 1.904-.073 2.786-.515-1.185-1.399-2.221-2.515-3.076.369-.208.779-.29 1.166-.29zM12.5 15.421c-.966 0-1.75.778-1.75 1.737 0 .959.784 1.737 1.75 1.737s1.75-.778 1.75-1.737c0-.959-.784-1.737-1.75-1.737zm7 0c-.966 0-1.75.778-1.75 1.737 0 .959.784 1.737 1.75 1.737s1.75-.778 1.75-1.737c0-.959-.784-1.737-1.75-1.737zm1.276 5.102c-1.048.927-2.794 1.484-4.776 1.484-1.982 0-3.728-.566-4.776-1.375.583 1.506 2.443 2.894 4.776 2.894 2.333 0 4.193-1.384 4.776-3.003z"
      fill="#FFF"
    />
  </svg>
);
