import React from "react";

function Icon() {
    return (
        <svg
            width="130"
            height="24"
            viewBox="0 0 130 24"
        >
            <defs>
                <path
                    id="path-1"
                    d="M0.00016 0.39168L129.4032 0.39168 129.4032 9.51808 0.00016 9.51808z"
                ></path>
                <path
                    id="path-3"
                    d="M0.126773333 0.512106667L26.93904 0.512106667 26.93904 5.33333333 0.126773333 5.33333333z"
                ></path>
            </defs>
            <g fill="none" stroke="none" strokeWidth="1">
                <g transform="translate(-1281 -3958)">
                    <g transform="translate(0 3930)">
                        <g transform="translate(1281 28)">
                            <g transform="translate(0 9.607)">
                                <mask id="mask-2" fill="#fff">
                                    <use xlinkHref="#path-1"></use>
                                </mask>
                                <path
                                    fill="#4C54D2"
                                    d="M129.403.392v1.433h-2.496v7.603h-1.804V1.825h-2.484V.392h6.784zm-10.137 5.85l-1.33-3.815-1.333 3.815h2.663zm.486 1.369h-3.622l-.64 1.817h-1.907L116.91.494h2.06l3.316 8.934h-1.907l-.628-1.817zm-8.998.032c.256-.21.384-.514.384-.915 0-.4-.13-.71-.39-.928-.26-.218-.63-.327-1.108-.327h-1.702v2.484h1.715c.478 0 .845-.105 1.101-.314zm-2.816-3.463h1.625c.453 0 .796-.1 1.03-.3.235-.2.353-.488.353-.864 0-.375-.115-.666-.345-.87-.231-.206-.585-.308-1.063-.308h-1.6V4.18zm4.55 1.364c.329.406.493.881.493 1.427 0 .76-.254 1.36-.761 1.798-.508.44-1.244.66-2.208.66h-3.879V.391h3.802c.913 0 1.614.2 2.105.601.49.402.737.965.737 1.69 0 .564-.148 1.024-.442 1.383a2.054 2.054 0 01-1.158.703c.545.111.983.37 1.312.775zM67.302.392v9.036h-1.817v-3.93h-3.866v3.93h-1.805V.392h1.805v3.66h3.866V.392H67.3zm-8.486 0v1.433H56.32v7.603h-1.805V1.825h-2.483V.392h6.784zm-9.587 9.036h1.805V.392h-1.805v9.036zM47.999.392l-2.214 9.036h-2.227l-1.69-6.553-1.703 6.553h-2.214L35.737.392h1.958l1.383 7.001L40.87.392h1.983l1.806 7.001L46.04.392h1.958zm-15.641 0v1.433h-2.496v7.603h-1.805V1.825h-2.483V.392h6.784zM22.105 8.047h3.034v1.381H20.3V.392h1.805v7.655zm-5.248 1.381h1.805V.392h-1.805v9.036zM9.856.392v5.3c0 .708.155 1.239.467 1.593.311.354.761.53 1.35.53.58 0 1.026-.176 1.338-.53.311-.354.467-.885.467-1.594v-5.3h1.792v5.3c0 .837-.156 1.542-.467 2.118a3.013 3.013 0 01-1.293 1.287c-.55.282-1.175.422-1.875.422-1.084 0-1.95-.324-2.598-.973-.65-.648-.973-1.6-.973-2.854v-5.3h1.792zM4.62 7.643c.256-.21.383-.514.383-.915 0-.4-.13-.71-.39-.928-.26-.218-.63-.327-1.107-.327H1.804v2.484H3.52c.477 0 .845-.105 1.101-.314zM1.804 4.18H3.43c.452 0 .796-.1 1.03-.3.235-.2.353-.488.353-.864 0-.375-.115-.666-.346-.87-.23-.206-.585-.308-1.063-.308h-1.6V4.18zm4.551 1.364c.328.406.493.881.493 1.427 0 .76-.254 1.36-.761 1.798-.508.44-1.245.66-2.208.66H0V.391h3.802c.912 0 1.614.2 2.105.601.49.402.736.965.736 1.69 0 .564-.147 1.024-.441 1.383a2.054 2.054 0 01-1.159.703c.546.111.983.37 1.312.775z"
                                    mask="url(#mask-2)"
                                ></path>
                            </g>
                            <path
                                fill="#FF4724"
                                d="M74.26 23.27l7.935-4.617 5.471-9.497V0c-.144 0-.289.095-.398.285l-6.48 11.249-6.48 11.249c-.11.19-.12.362-.048.487"
                            ></path>
                            <path
                                fill="#9E1F63"
                                d="M87.666 0v9.156l5.47 9.497 7.937 4.617c.071-.125.06-.297-.048-.487l-6.48-11.25L88.064.286C87.954.095 87.81 0 87.666 0"
                            ></path>
                            <g transform="translate(74.133 18.14)">
                                <mask id="mask-4" fill="#fff">
                                    <use xlinkHref="#path-3"></use>
                                </mask>
                                <path
                                    fill="#662D91"
                                    d="M26.939 5.13L19.004.512H8.062L.127 5.13c.07.126.225.203.444.203h25.924c.218 0 .373-.077.444-.203"
                                    mask="url(#mask-4)"
                                ></path>
                            </g>
                            <path
                                fill="#FFF"
                                d="M82.1953067 18.6528L93.13664 18.6528 87.66624 9.15626667z"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default Icon;
