import React from 'react'

export const SwipeRightIcon = () => (
  <svg width='26' height='26' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0 13C0 5.832 5.831 0 13 0c7.168 0 13 5.832 13 13s-5.832 13-13 13C5.831 26 0 20.168 0 13zm10.26 5.673a1.001 1.001 0 0 0 1.413.067l5.5-5c.417-.38.417-1.101 0-1.48l-5.5-5a1.001 1.001 0 0 0-1.346 1.48L15.013 13l-4.686 4.26a.998.998 0 0 0-.067 1.413z'
      fill='#5E6175'
    />
  </svg>
)

export const SwipeLeftIcon = () => (
  <svg width='26' height='26' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M26 13c0 7.168-5.831 13-13 13-7.168 0-13-5.832-13-13S5.832 0 13 0c7.169 0 13 5.832 13 13zM15.74 7.327a1.001 1.001 0 0 0-1.413-.067l-5.5 5c-.417.38-.417 1.101 0 1.48l5.5 5a1.001 1.001 0 0 0 1.346-1.48L10.987 13l4.686-4.26a.998.998 0 0 0 .067-1.413z'
      fill='#5E6175'
    />
  </svg>
)
