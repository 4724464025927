import React from "react";

function Icon(props) {
    return (
        <svg
            width="95"
            height={ props.height || 20 }
            viewBox="0 0 95 20"
        >
            <g fill="none" fillRule="evenodd">
                <rect
                    width="95"
                    height="20"
                    fill="rgba(255,255,255,.85)"
                    rx="10"
                ></rect>
                <path
                    fill="#FF4724"
                    d="M55.46 14.85l3.83-2.24 2.65-4.59V3.6c-.07 0-.14.04-.2.13l-3.13 5.44-3.13 5.44c-.05.1-.06.17-.02.24"
                ></path>
                <path
                    fill="#9E1F63"
                    d="M61.94 3.6v4.42l2.64 4.6 3.84 2.23a.26.26 0 00-.02-.24l-3.14-5.44-3.13-5.44c-.05-.09-.12-.13-.2-.13"
                ></path>
                <path
                    fill="#662D91"
                    d="M68.42 14.85l-3.84-2.24H59.3l-3.83 2.24c.03.06.1.1.21.1h12.54c.1 0 .18-.04.21-.1"
                ></path>
                <path fill="#FFF" d="M59.3 12.61h5.29l-2.65-4.59z"></path>
                <path
                    fill="#662D91"
                    fillRule="nonzero"
                    d="M12.14 11c0-.34-.24-.55-.64-.55h-.87v1.09h.88c.4 0 .63-.18.63-.53zm-.12-1.93c0-.34-.21-.52-.6-.52h-.79v1.03h.78c.4 0 .61-.18.61-.51zm1.33 2.1c0 .8-.6 1.33-1.63 1.33H9.43V7.6h2.22c1 0 1.6.5 1.6 1.27 0 .6-.37.98-.84 1.12a1.2 1.2 0 01.94 1.19zm1.18-.65V7.6h1.2v2.94c0 .58.28.92.85.92.56 0 .87-.34.87-.92V7.59h1.2v2.93c0 1.36-.96 2.03-2.1 2.03-1.14 0-2.02-.67-2.02-2.03zm5.45 1.98V7.6h1.2v4.91h-1.2zm2.57-4.91h1.2v3.99h1.56v.92h-2.76V7.6zm3.57.96v-.96h3.8v.96h-1.3v3.95h-1.2V8.55h-1.3zm7.87 3.95l-1.24-4.9h1.28l.7 3.58.88-3.58h1.32l.84 3.58.71-3.58h1.29l-1.28 4.91h-1.45l-.78-3.23-.82 3.23H34zm6.88 0V7.6h1.2v4.91h-1.2zm2.3-3.95v-.96h3.8v.96h-1.3v3.95h-1.2V8.55h-1.3zm7.96 3.95v-2.02h-1.86v2.02h-1.2V7.6h1.2v1.9h1.86V7.6h1.2v4.91h-1.2zM73.14 11c0-.34-.24-.55-.64-.55h-.87v1.09h.88c.4 0 .63-.18.63-.53zm-.12-1.93c0-.34-.21-.52-.6-.52h-.79v1.03h.79c.39 0 .6-.18.6-.51zm1.33 2.1c0 .8-.6 1.33-1.63 1.33h-2.29V7.6h2.22c1 0 1.6.5 1.6 1.27 0 .6-.36.98-.84 1.12a1.2 1.2 0 01.94 1.19zm4.55 1.33l-.3-.86h-1.83l-.3.86h-1.25L77 7.6h1.39l1.78 4.91H78.9zm-.6-1.79l-.61-1.8-.6 1.8h1.2zm2.64-2.16v-.96h3.8v.96h-1.3v3.95h-1.2V8.55h-1.3z"
                ></path>
            </g>
        </svg>
    );
}

export default Icon;
